import { ScalapayComponent } from './scalapay/scalapay.component';
import { CouponRouteComponent } from './coupon-route/coupon-route.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loggedActivated, loggedChildActivated } from 'src/app/core/auth-guard/islogged-guard/is-logged.guard';
import { BaseComponent } from './base.component';
import { SimulationShorterComponent } from './simulation-shorter/simulation-shorter.component';

const childRoutes: Routes = [
  {
    path: 'planner',
    loadChildren: () => import('./planner/planner.module').then(m => m.PlannerModule),
  },
  {
    path: 'metrics',
    loadChildren: () => import('./metrics/metrics.module').then(m => m.MetricsModule),
  },
  {
    path: 'statistics',
    loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
  },
  {
    path: 'simulations-light',
    loadChildren: () => import('./simulations-light/simulations-light.module').then(m => m.SimulationsLightModule),
  },
  {
    path: 'test/:uid',
    loadChildren: () => import('./test/test.module').then(m => m.TestModule),
    data: { test: {} },
  },
  {
    path: 'errors/:uid',
    loadChildren: () => import('./test/test.module').then(m => m.TestModule),
    data: { test: {} },
  },
  {
    path: 'community',
    loadChildren: () => import('./community/community.module').then(m => m.CommunityModule),
    data: { state: 'community' },
  },
  {
    path: 'archive',
    loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule),
  },
  {
    path: 'live',
    loadChildren: () => import('./rec-room/rec-room.module').then(m => m.RecRoomModule),
    data: { state: 'live', is_rec_room: false },
  },
  {
    path: 'rec',
    loadChildren: () => import('./rec-room/rec-room.module').then(m => m.RecRoomModule),
    data: { is_rec_room: true },
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'product/:uid',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
  },
  {
    path: 's/:token',
    loadChildren: () => import('./shorter/shorter.module').then(m => m.ShorterModule),
  },
  {
    path: 'simulations',
    loadChildren: () => import('./prefabs/prefabs.module').then(m => m.PrefabsModule),
    data: { state: 'simulations' },
  },
  {
    path: 'folder',
    loadChildren: () => import('./question-folder/question-folder.module').then(m => m.QuestionFolderModule),
  },
];

const gRoutes: Routes = [
  {
    path: '',
    canActivate: [loggedActivated],
    canActivateChild: [loggedChildActivated],
    component: BaseComponent,
    children: childRoutes,
  },
  { path: 'ssh/:token', component: SimulationShorterComponent },
  { path: 'scalapay', component: ScalapayComponent },
  {
    path: 'sh/:token',
    loadChildren: () => import('./shorter/shorter.module').then(m => m.ShorterModule),
  },
  { path: 'coupon/:coupon', component: CouponRouteComponent },
  { path: '**', redirectTo: `/` },
];

@NgModule({
  imports: [RouterModule.forChild(gRoutes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
